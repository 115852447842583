<template>
  <router-view></router-view>
</template>

<script>
import {uiHelper, locale} from '@/util'

export default {
  name: 'SlotLayout',
  // metaInfo: {
  //   title: `${locale.getMessage('meta.slot_title')}`,
  //   titleTemplate: `%s | ${uiHelper.getHost()}`,
  //   meta: [{charset: 'utf-8'}, {
  //     name: 'description',
  //     content: `${locale.getMessage('meta.slot_description')}`
  //   }, {name: 'keyword', content: `${locale.getMessage('meta.slot_keyword')}`}]
  // },
  methods: {
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}

</script>

<style>
.game_icon_sizing {
  border-radius: 15px !important;
}
</style>